import "../App.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useState } from "react";

export default function Slidebar() {
  const [select, setSelect] = useState(0);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleMenuItemClick = (menuItemIndex) => {
    // Update the selected menu item and close the sidebar
    setSelect(menuItemIndex);
    setIsNavOpen(false);
  };

  return (
    <>
      <section className="flex mobile-menu">
        <div
          className="space-y-2 cursor-pointer HAMBURGER-ICON"
          onClick={() => setIsNavOpen((prev) => !prev)}
        >
          <span className="block h-0.5 w-8 animate-pulse bg-violet-600"></span>
          <span className="block h-0.5 w-8 animate-pulse bg-violet-600"></span>
          <span className="block h-0.5 w-8 animate-pulse bg-violet-600"></span>
        </div>

        <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
          <div
            className="absolute top-0 right-0 px-4 py-4 cursor-pointer"
            onClick={() => setIsNavOpen(false)}
          >
            <svg
              className="w-8 h-8 text-violet-600"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </div>

          <div className="flex-none h-screen min-w-[25%]">
            <div className="flex flex-col justify-center w-full gap-5 mt-10 overflow-hidden text-lg text-center text-white align-middle nav">
              <div data-aos="slide-down">
                <img
                  src={require("../assets/images/MyImage.png")}
                  alt="Rupesh Sah"
                  className="rounded-full cursor-pointer min-h-fit mx-auto  max-w-[190px] max-h-[190px]"
                />
                <h3 className="py-4 font-medium text-white name ">
                  Rupesh Sah
                </h3>
              </div>
              <p
                onClick={() => handleMenuItemClick(0)}
                className={`cursor-pointer hover:text-violet-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${
                  select === 0 ? "text-violet-600 " : ""
                }`}
                data-aos="slide-right"
                data-aos-delay="200"
              >
                <a href="/#">Home</a>
              </p>
              <p
                onClick={() => handleMenuItemClick(1)}
                className={`cursor-pointer hover:text-violet-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${
                  select === 1 ? "text-violet-600 " : ""
                }`}
                data-aos-delay="600"
                data-aos="slide-left"
              >
                <a href="#about"> About Me</a>
              </p>
              <p
                onClick={() => handleMenuItemClick(2)}
                className={`cursor-pointer hover:text-violet-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${
                  select === 2 ? "text-violet-600 " : ""
                }`}
                data-aos="slide-right"
                data-aos-delay="1000"
              >
                <a href="#resume">Experience</a>
              </p>
              <p
                onClick={() => handleMenuItemClick(3)}
                className={`cursor-pointer hover:text-violet-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${
                  select === 3 ? "text-violet-600 " : ""
                }`}
                data-aos="slide-left"
                data-aos-delay="1400"
              >
                <a href="#projects">Projects</a>
              </p>
              <p
                onClick={() => handleMenuItemClick(4)}
                className={`cursor-pointer hover:text-violet-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${
                  select === 4 ? "text-violet-600 " : ""
                }`}
                data-aos="slide-right"
                data-aos-delay="1800"
              >
                <a href="#contact">Contact</a>
              </p>
            </div>
            <div
              className="flex flex-row gap-5 mx-auto text-white w-fit pt-7"
              data-aos="slide-up"
              data-aos-delay="2300"
            >
              <div
                onClick={() =>
                  window.open("https://github.com/Rupeshsah23", "_blank")
                }
              >
                <GitHubIcon className="cursor-pointer hover:scale-105" />
              </div>
              <div
                onClick={() =>
                  window.open("https://instagram.com/rupesh_vamps", "_blank")
                }
              >
                {" "}
                <InstagramIcon className="cursor-pointer hover:scale-105" />
              </div>
              <div
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/er-rupesh-sah-0775a5135",
                    "_blank"
                  )
                }
              >
                {" "}
                <LinkedInIcon className="cursor-pointer hover:scale-105" />
              </div>
              <div
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/rupesh.shah.9083",
                    "_blank"
                  )
                }
              >
                <FacebookIcon className="cursor-pointer hover:scale-105" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="flex-none  bg-black h-screen min-w-[25%] fixed desktop-menu">
        <div className="flex flex-col justify-center w-full gap-5 mt-10 overflow-hidden text-lg text-center text-white align-middle nav">
          <div data-aos="slide-down">
            <img
              src={require("../assets/images/MyImage.png")}
              alt="Rupesh Sah"
              className="rounded-full min-h-fit mx-auto  max-w-[190px] max-h-[190px]"
            />
            <h3 className="py-4 font-medium text-white name ">Rupesh Sah</h3>
          </div>
          <p
            onClick={() => setSelect(0)}
            className={`cursor-pointer hover:text-violet-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${
              select === 0 ? "text-violet-600 " : ""
            }`}
            data-aos="slide-right"
            data-aos-delay="200"
          >
            <a href="/#">Home</a>
          </p>
          <p
            onClick={() => setSelect(1)}
            className={`cursor-pointer hover:text-violet-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${
              select === 1 ? "text-violet-600 " : ""
            }`}
            data-aos-delay="600"
            data-aos="slide-left"
          >
            <a href="#about"> About Me</a>
          </p>
          <p
            onClick={() => setSelect(2)}
            className={`cursor-pointer hover:text-violet-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${
              select === 2 ? "text-violet-600 " : ""
            }`}
            data-aos="slide-right"
            data-aos-delay="1000"
          >
            <a href="#resume">Experience</a>
          </p>
          <p
            onClick={() => setSelect(3)}
            className={`cursor-pointer hover:text-violet-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${
              select === 3 ? "text-violet-600 " : ""
            }`}
            data-aos="slide-left"
            data-aos-delay="1400"
          >
            <a href="#projects">Projects</a>
          </p>
          <p
            onClick={() => setSelect(4)}
            className={`cursor-pointer hover:text-violet-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${
              select === 4 ? "text-violet-600 " : ""
            }`}
            data-aos="slide-right"
            data-aos-delay="1800"
          >
            <a href="#contact">Contact</a>
          </p>
        </div>
        <div
          className="flex flex-row gap-5 mx-auto text-white w-fit pt-7"
          data-aos="slide-up"
          data-aos-delay="2300"
        >
          <div
            onClick={() =>
              window.open("https://github.com/Rupeshsah23", "_blank")
            }
          >
            <GitHubIcon className="cursor-pointer hover:scale-105" />
          </div>
          <div
            onClick={() =>
              window.open("https://instagram.com/rupesh_vamps", "_blank")
            }
          >
            {" "}
            <InstagramIcon className="cursor-pointer hover:scale-105" />
          </div>
          <div
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/er-rupesh-sah-0775a5135",
                "_blank"
              )
            }
          >
            {" "}
            <LinkedInIcon className="cursor-pointer hover:scale-105" />
          </div>
          <div
            onClick={() =>
              window.open("https://www.facebook.com/rupesh.shah.9083", "_blank")
            }
          >
            <FacebookIcon className="cursor-pointer hover:scale-105" />
          </div>
        </div>
      </div>
    </>
  );
}
