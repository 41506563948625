import "../../App.css";
import DownloadIcon from "@mui/icons-material/Download";
// import LearningCode from "../../assets/images/learn-coding-clipart.svg";
// import * as LottiePlayer from "@lottiefiles/lottie-player";

export default function About() {
  const getDate = () => {
    var dob = new Date("02/28/1994");
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);
    return age;
  };
  return (
    <div
      id="about"
      className="flex flex-col min-h-screen gap-5 text-lg font-normal text-center text-black bg-white"
    >
      <div className="mt-12 text-5xl font-bold head" data-aos={"slide-down"}>
        About Me
      </div>
      <div className="flex flex-row flex-wrap gap-6 ml-6 mr-6">
        <div className="flex flex-col flex-auto w-64 gap-6 max-w-sm:w-full mt-14 about-heading-wrapper">
          <h3 className="text-3xl font-medium" data-aos={"fade-left"}>
            I'm <span className="text-violet-600">Rupesh Sah,</span> a Web
            Enthusiast
          </h3>
          <p
            className="pt-5 leading-7 text-justify text-slate-900"
            data-aos={"fade-left"}
          >
            I love playing music, reading comics and playing games. I am
            graduated Bachelor degree in Computer Engineering at Acme
            Engineering college. I am {getDate()} years old. I love exploring
            new technologies.
          </p>
          <p
            className="leading-7 text-justify text-slate-900"
            data-aos={"fade-right"}
          >
            I have 4 years of experience in IT Industry. I worked on Shopify
            Liquid Template, reactJs, javaScript, CSS and many more. I am also
            looking to work on freelance projects.
          </p>
        </div>

        <div
          className="relative flex-auto w-32 pl-4 max-w-sm:w-full sm:rounded-lg about-animation-wrapper"
          data-aos={"slide-left"}
        >
          {/* <img
            src={LearningCode}
            alt="Learning Code"
            className="motion-safe:animate-zoomy"
          /> */}
          <div className="motion-safe:animate-zoomy">
            <lottie-player
              autoplay
              loop
              mode="bounce"
              src="https://assets10.lottiefiles.com/packages/lf20_w98qte06.json"
              style={{ width: "350px" }}
            />
          </div>
          <button className="py-3 mt-5 text-white mx-auto px-9 bg-violet-600 border-2 w-fit border-[#5918df]-500 rounded-3xl  hover:-translate-y-1.5 duration-[350ms] hover:duration-[350ms] hover:bg-violet-800 hover:scale-[1.023] focus:bg-violet-800 animate-zoomy">
            <a
              href={require("../../assets/files/RupeshsahResume.pdf")}
              download={"Rupesh'sResume.pdf"}
            >
              Download CV
              <DownloadIcon />
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}
