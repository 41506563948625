export const projects = [
  {
    title: "Rice Spice Dice",
    desc: "Transformed the Rice Spice Dice online store using the Shopify e-commerce platform, enhancing the overall customer journey. Incorporated intuitive browsing, engaging product showcases, and streamlined checkout for an improved shopping experience.",
    tags: [
      {
        name: "Shopify",
        color: "red-600",
      },
      {
        name: "Liquid Template",
        color: "green-600",
      },
      {
        name: "ReCustomization",
        color: "yellow-600",
      },
    ],
    img: "https://github.com/Rupeshsah23/personal-photos/assets/123934286/e3435bca-10bf-4757-bb64-a005197b40b6",
    link: "https://www.ricespicedice.com.au/",
    // code: "https://github.com/code-cracked/plants-vs-zombies-api",
  },
  {
    title: "Kaimono Japan",
    desc: "Revamped Kaimono Japan Store using Shopify e-commerce, elevating customer experience. Integrated user-friendly navigation, captivating product displays, and efficient checkout.",
    tags: [
      {
        name: "Shopify Redesign",
        color: "red-600",
      },
      {
        name: "Liquid Template",
        color: "green-600",
      },
      {
        name: "ReCustomization",
        color: "yellow-600",
      },
    ],
    img: "https://github.com/Rupeshsah23/personal-photos/assets/123934286/7d5d1d3c-b213-4be5-9770-888f5c34e6e8",
    link: "https://kaimono.co.jp/",
    // code: "https://github.com/code-cracked/plants-vs-zombies-api",
  },
  {
    title: "SupplyBox",
    desc: "Crafted SupplyBox Nepal from the ground up, utilizing Shopify e-commerce for an unparalleled customer journey. Designed seamless navigation, compelling product showcases, and intuitive checkout, optimizing usability",
    tags: [
      {
        name: "Shopify",
        color: "red-600",
      },
      {
        name: "Liquid Templates",
        color: "green-600",
      },
      {
        name: "App Intergations",
        color: "yellow-600",
      },
      {
        name: "Design",
        color: "blue-800",
      },
    ],
    img: "https://github.com/Rupeshsah23/personal-photos/assets/123934286/a6683566-cef1-4705-86a4-e6756ee3a7fc",
    link: "https://supplybox.com.np/en",
    // code: "https://github.com/daniel-jebarson/web-chat-app",
  },
  {
    title: "Otakuwarudo",
    desc: "Transformed Otakuwarudo with comprehensive redesign and structural customization.",
    tags: [
      {
        name: "Shopify",
        color: "red-600",
      },
      {
        name: "Liquid Templates",
        color: "green-600",
      },
      {
        name: "Store Design",
        color: "yellow-600",
      },
      {
        name: "Theme Customization",
        color: "blue-800",
      },
    ],
    img: "https://github.com/Rupeshsah23/personal-photos/assets/123934286/36bdeb4f-546c-4ab9-976b-0e9b6a9ca382",
    link: "https://www.otakuwarudo.com/",
    // code: "https://github.com/daniel-jebarson/EXPLOITER",
  },
  {
    title: "Reuseable Shopify Components",
    desc: "I've developed a set of versatile and reusable Shopify components aimed at optimizing online stores. These custom-built elements, including dynamic product displays, streamlined checkout modules, and interactive review sections, enhance both design consistency and user experience. This effort boosts efficiency and maintains a polished, user-centric e-commerce environment.",
    tags: [
      {
        name: "Shopify Components",
        color: "red-600",
      },
      {
        name: "Liquid Code",
        color: "green-600",
      },
      {
        name: "Sections",
        color: "purple-600",
      },
      {
        name: "Theme Customization",
        color: "blue-800",
      },
    ],
    img: "https://github.com/Rupeshsah23/personal-photos/assets/123934286/c3434e80-3fe4-43ca-822e-ee26f4910ea3",
    link: "https://github.com/Rupeshsah23/Reusable-Shopify-Component/tree/main#readme",
    // code: "https://github.com/Rupeshsah23/Reusable-Shopify-Component/tree/main#readme",
  },
  {
    title: "Hotel Booking Prototype",
    desc: "Conceptualized and materialized a cutting-edge hotel booking prototype from Figma designs. Seamlessly translated design elements into an interactive interface, offering intuitive room selection, date picking, and secure payment simulation",
    tags: [
      {
        name: "Figma Design",
        color: "red-600",
      },
      {
        name: "Figma Puglins",
        color: "green-600",
      },
      {
        name: "Interaction",
        color: "green-600",
      },
    ],
    img: "https://github.com/Rupeshsah23/Hotel-Booking-UX/assets/123934286/966e52ce-1edd-4557-80b1-0e36b2cb4181",
    link: "https://www.figma.com/proto/i4QbFrx0ZFrOAEbhLI7599/Hotel-Booking-Platform?page-id=0%3A1&node-id=8-265&viewport=437%2C362%2C0.15&scaling=scale-down-width&starting-point-node-id=8%3A265",
    code: "https://github.com/Rupeshsah23/Hotel-Booking-UX/blob/main/README.md",
  },
  {
    title: "ProYoga Buddy Prototype",
    desc: "Developed the ProYoga Buddy prototype, seamlessly bringing Figma designs to life. Crafted an interactive interface that facilitates yoga enthusiasts in planning routines, accessing pose tutorials, and tracking progress.",
    tags: [
      {
        name: "Figma Design",
        color: "red-600",
      },
      {
        name: "Figma Puglins",
        color: "green-600",
      },
      {
        name: "Interactions",
        color: "yellow-600",
      },
    ],
    img: "https://github.com/Rupeshsah23/Hotel-Booking-UX/assets/123934286/c756ca1b-328c-41d0-b6e2-e333c19ac052",
    link: "https://www.figma.com/proto/LB8KWw1uE6Lrql1BGQMzBq/ProYoga-Buddy-Classes?page-id=13%3A393&node-id=13-538&viewport=783%2C733%2C0.22&scaling=min-zoom&starting-point-node-id=13%3A538",
    code: "https://github.com/Rupeshsah23/Hotel-Booking-UX/blob/main/README.md",
  },
];
