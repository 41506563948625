import BarGraph from "./BarGraph";
import DownloadIcon from "@mui/icons-material/Download";

export default function Resume() {
  return (
    <div id="resume" className="flex-col min-h-screen text-center">
      <div className="py-8 text-5xl font-bold" data-aos={"slide-down"}>
        Work Experience
      </div>
      <div className="grid grid-cols-2 gap-7 mt-7 experience---wrapper">
        <ol className="relative mb-6 border-l border-gray-700 ml-9 experience-wrapper">
          <li className="mb-10" data-aos="zoom-out" data-aos-delay="700">
            <span className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-violet-900 -left-3 ring-8 ring-gray-900">
              <svg
                aria-hidden="true"
                className="w-3 h-3 text-violet-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <h3 className="mb-1 text-lg font-semibold text-white">
              Game Analysts at CloudFactory
            </h3>
            <time className="block pt-1 pb-3 text-sm font-normal leading-none text-gray-500">
              "2018 - 2019"
            </time>
            <p className="mb-4 text-base font-normal text-gray-400">
              I work as a game analyst at CloudFactory, examining player data to
              extract insights that drive game enhancements. My role involves
              deciphering player behavior, identifying trends, and offering
              data-driven recommendations to the development team. Through
              meticulous analysis, I contribute to optimizing the gaming
              experience and fostering player engagement.
            </p>
          </li>
          <li className="mb-10" data-aos="zoom-in">
            <span className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-violet-900 -left-3 ring-8 ring-gray-900">
              <svg
                aria-hidden="true"
                className="w-3 h-3 text-violet-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <h3 className="flex items-center justify-center mb-1 text-lg font-semibold text-white">
              Shopify Developer and Web Designer at Cartmade Ecommerce PVT LTD.
            </h3>
            <time className="block pt-1 pb-3 text-sm font-normal leading-none text-gray-500">
              "2019 - 2022"
            </time>
            <p className="mb-4 text-base font-normal text-gray-400">
              In my capacity as a Shopify Developer and Web Designer at Cartmade
              Ecommerce PVT LTD, I merge technical proficiency with creative
              flair. I specialize in building dynamic Shopify e-commerce
              platforms, ensuring visually appealing designs and seamless
              functionality. My role focuses on crafting user-centered online
              shopping experiences to elevate brand presence and customer
              satisfaction.
            </p>
            {/* <a
              href="https://www.credly.com/badges/bf6b9024-d4f8-465d-b345-d9b0c5a7abab/public_url"
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center px-4 py-2 mt-2 text-sm font-medium text-gray-400 bg-gray-800 border border-gray-600 rounded-lg focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                  clipRule="evenodd"
                ></path>
              </svg>{" "}
              Download Certificate
            </a> */}
          </li>
          <li className="mb-10" data-aos="zoom-in">
            <span className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-violet-900 -left-3 ring-8 ring-gray-900">
              <svg
                aria-hidden="true"
                className="w-3 h-3 text-violet-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <h3 className="flex items-center justify-center mb-1 text-lg font-semibold text-white">
              Shopify Developer and UI/UX Designer at Outcode Software LLC.
            </h3>
            <time className="block pt-1 pb-3 text-sm font-normal leading-none text-gray-500">
              "2022 - Present"
            </time>
            <p className="mb-4 text-base font-normal text-gray-400">
              I'm a Shopify Developer and UI/UX Designer at Outcode Software
              LLC. My dual role involves crafting engaging user experiences
              through intuitive designs while also developing and optimizing
              Shopify-based e-commerce solutions. I blend creative design with
              technical expertise to create seamless online shopping journeys
              and drive business growth.
            </p>
          </li>
          <li data-aos="zoom-in" data-aos-offset={"-15"} data-aos-delay="1300">
            <span className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-violet-900 -left-3 ring-8 ring-gray-900">
              <svg
                aria-hidden="true"
                className="w-3 h-3 text-violet-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
            <h3 className="mb-1 text-lg font-semibold text-white">
              Figma UI/UX Web Design & Animation
            </h3>
            <time className="block mb-2 text-sm font-normal leading-none text-gray-500">
              Completed on March 24, 2023
            </time>
            <p className="text-base font-normal text-gray-400">
              Successfully completed Udemy's Figma UI/UX Web Design & Animation
              course. Acquired advanced skills in crafting captivating user
              interfaces, seamless user experiences, and engaging animations
              using Figma. Proficiently transformed design concepts into
              interactive prototypes, expanding proficiency in modern web design
              techniques and enhancing the ability to create visually appealing
              and functional websites.
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.udemy.com/certificate/UC-f6b354e9-0a66-492d-958c-6e3eeca1b0fa/"
              className="inline-flex items-center px-4 py-2 mt-5 text-sm font-medium text-gray-400 bg-gray-800 border border-gray-600 rounded-lg focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
            >
              View Certificate
            </a>
          </li>
        </ol>

        <div
          className="flex flex-col text-center skills-wrapper"
          data-aos="slide-up"
        >
          <div className="py-3 ml-8 mr-8 text-3xl font-bold skill-title-wrapper ">
            My Skills
          </div>
          <div className="flex flex-col gap-8 mt-6 ml-8 mr-8 skill-list">
            <BarGraph name={"HTML/CSS/SCSS"} percent={99} />
            <BarGraph name={"Shopify Liquid Templates"} percent={90} />
            <BarGraph name={"JavaScript"} percent={60} />
            <BarGraph name={"React"} percent={40} />
            <BarGraph name={"Figma"} percent={65} />
            <BarGraph name={"TypeScript"} percent={15} />
            <BarGraph name={"Photoshop"} percent={40} />
            <BarGraph name={"Webflow"} percent={75} />
          </div>

          <a
            href={require("../../assets/files/RupeshsahResume.pdf")}
            download={"Rupesh's Resume"}
            className="inline-flex items-center px-6 py-2 mx-auto mt-12 text-sm font-medium border border-gray-600 rounded-lg text-white-400 bg-violet-800 w-fit focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
          >
            Download CV <DownloadIcon />
          </a>
        </div>
      </div>
    </div>
  );
}
