import Typewriter from "typewriter-effect";
import Spline from "@splinetool/react-spline";
import GraphemeSplitter from "grapheme-splitter";
import "../../App.css";

export default function Home() {
  const stringSplitter = (string) => {
    const splitter = new GraphemeSplitter();
    return splitter.splitGraphemes(string);
  };
  return (
    <div className="grid content-center min-h-screen grid-cols-1 text-center bg-center bg-no-repeat bg-cover banner-wrapper gap-7 name">
      <div className="relative banner-wrapper">
        {/* <Spline scene="https://prod.spline.design/3mmwpJs3SDFf2dDY/scene.splinecode" /> */}
        <Spline
          scene="https://prod.spline.design/8mGXwOq4AJ2Hhmf6/scene.splinecode"
          className="spline-background"
          style={{ height: "100vh" }}
        />
        {/* <Spline
                className='spline-container'
                scene='https://prod.spline.design/1DYnFsiKosxp3rXB/scene.splinecode'
                
            /> */}
        <h1
          className="head text-5xl font-semibold tracking-[3px] pb-2 "
          data-aos="zoom-in-up"
        >
          {/* Welcome */}
        </h1>
        <div
          className="pb-16 text-5xl text-white-700 title"
          data-aos-delay="700"
          data-aos="zoom-in"
        >
          Hi, my name is{" "}
          <span className="text-violet-800 text-700 background-wrap">
            Rupesh Sah
          </span>{" "}
          <br></br>i <span className="text-500 cursive">design</span> and
          develop{" "}
          <Typewriter
            options={{
              strings: ["Websites", "UI/UX", "Shopify Ecommerce Stores"],
              delay: 150,
              pauseFor: 1500,
              autoStart: true,
              loop: true,
              stringSplitter: stringSplitter,
            }}
          />
        </div>
      </div>
      <button
        className="py-2 mx-auto px-7 bg-violet-600 border-2 w-fit border-violet-500 rounded-3xl  hover:-translate-y-1.5 duration-[350ms] hover:duration-[350ms]"
        data-aos="zoom-out-up"
        data-aos-delay="1400"
      >
        <a href="#contact">Contact Me</a>
      </button>
      <div
        className=" cursor-pointer w-fit left-[50%] absolute bottom-16 arrow-wrapper transform-[50%]"
        data-aos="flip-up"
        data-aos-offset="20"
        data-aos-delay="2000"
      >
        <a href="#about" className="text-slate-400">
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            className="motion-safe:animate-bounce"
          >
            <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm5.247 8l-5.247 6.44-5.263-6.44-.737.678 6 7.322 6-7.335-.753-.665z" />
          </svg>
        </a>
      </div>
    </div>
  );
}
